import React, { useState } from "react";
import {
  View,
  TextInput,
  FlatList,
  Text,
  StyleSheet,
  Pressable,
  Linking,
  Dimensions,
} from "react-native";
import { capitalizeString } from "../../helpers";

const ws = Dimensions.get("window").width;

const SearchableTextInput = ({
  data,
  query,
  setQuery,
  selectedSubject,
  setSelectedSubject,
}) => {
  const [filteredData, setFilteredData] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [hasResults, setHasResults] = useState(true); // New state variable
  const [isSchFsc, setIsSchFsc] = useState(false); // New state variable
  const handleSearch = (text) => {
    const filteredItems = data.filter((category) => {
      const filteredCategoryData = category.data.filter((item) =>
        item.title.toLowerCase().includes(text.toLowerCase())
      );
      return filteredCategoryData.length > 0;
    });
    if (selectedSubject) {
      if (text !== selectedSubject.title) {
        setSelectedSubject("");
      }
    }
    setQuery(text);
    setFilteredData(filteredItems);
    setShowResults(true);
    setHasResults(filteredItems.length > 0); // Update hasResults state
  };
  const searchFocus = () => {
    const filteredItems = data.filter((category) => {
      const filteredCategoryData = category.data;
      return filteredCategoryData.length > 0;
    });
    setIsSchFsc(true);
    setFilteredData(filteredItems);
    setShowResults(true);
    setHasResults(filteredItems.length > 0);
  };
  const searchBlur = () => {
    setTimeout(function () {
      if (!query) setIsSchFsc(false);
    }, 300);
  };

  const handlePressTitle = (item) => {
    setSelectedSubject(item);
    setQuery(item.title);
    setShowResults(false);
  };

  const renderTitle = ({ item }) => {
    const truncateTitle = (title, limit) => {
      if (title.length > limit) {
        return title.replaceAll("-", " ").substring(0, limit - 1) + "...";
      }
      return title.replaceAll("-", " ");
    };

    const keyWords = item.key.split("_");
    var lastWord = keyWords[keyWords.length - 1];
    if (lastWord.trim() === "projectBased") lastWord = "Projects";
    if (lastWord.trim() === "dictionary") lastWord = "Conversation";

    const renderLevel = () => {
      switch (keyWords[keyWords.length - 2]) {
        case "L1":
          return "Level 1 difficulty (appropriate for ages 4-10)";
        case "L2":
          return "Level 2 difficulty (appropriate for ages 8-12)";
        case "L3":
          return "Level 3 difficulty (appropriate for ages 10-18)";
        case "L4":
          return "Level 4 difficulty (appropriate for ages 14+)";

        default:
          return "Invalid difficulty level";
      }
    };

    return (
      <Pressable
        onPress={() => handlePressTitle(item)}
        style={(state) => [
          styles.titleContainer,
          state.hovered && styles.hoveredTitle,
        ]}
      >
        <Text style={styles.titleText}>{truncateTitle(item.title, 27)}</Text>
        <Text style={styles.keyText}>{capitalizeString(lastWord)}</Text>
        <Text style={styles.keyText}>{renderLevel()}</Text>
      </Pressable>
    );
  };

  const renderCategory = ({ item }) => {
    const filteredCategoryData = item.data.filter((title) =>
      title.title.toLowerCase().includes(query.toLowerCase())
    );
    if (filteredCategoryData.length === 0) {
      return null; // Return null if there are no results
    }
    return (
      <View style={styles.categoryContainer}>
        <Text style={styles.categoryText}>
          {item.category.replaceAll("-", " ")}
        </Text>
        <FlatList
          data={filteredCategoryData}
          renderItem={renderTitle}
          keyExtractor={(item) => item.key}
        />
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <TextInput
        style={styles.input}
        value={query}
        onChangeText={handleSearch}
        placeholder="Search for a subject"
        onFocus={searchFocus}
        onBlur={searchBlur}
      />
      <Pressable
        onPress={() =>
          Linking.openURL(
            "mailto:richkingsford@grapeassignments.com?subject=Grape%20Assignments%20-%20New%20Setup%20Request"
          )
        }
        style={{ marginVertical: 4 }}
      >
        <Text style={{ textDecorationLine: "underline" }}>
          Let us know if you'd like a different setup
        </Text>
      </Pressable>
      <Pressable
        onPress={() =>
          Linking.openURL(
            "mailto:richkingsford@grapeassignments.com?subject=Bulk%20discounts%20for%20Grape%20Assignments"
          )
        }
        style={{ marginVertical: 4 }}
      >
        <Text style={{ textDecorationLine: "underline" }}>
          Ask about bulk discounts
        </Text>
      </Pressable>

      {(isSchFsc || query) && (
        <View style={styles.resultsContainer}>
          {hasResults ? (
            <FlatList
              data={filteredData}
              renderItem={renderCategory}
              keyExtractor={(item) => item.category}
              showsVerticalScrollIndicator={false}
            />
          ) : (
            <Text style={styles.noResultsText}>No results found.</Text>
          )}
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "relative",
  },
  input: {
    width: ws > 500 ? 280 : 250,
    borderWidth: 1,
    borderColor: "gray",
    borderRadius: 4,
    padding: 8,
    backgroundColor: "white",
  },
  resultsContainer: {
    position: "absolute",
    top: "100%",
    width: 400,
    maxHeight: 500,
    borderWidth: 1,
    borderColor: "gray",
    borderRadius: 4,
    backgroundColor: "white",
    zIndex: 1,
  },
  categoryContainer: {},
  categoryText: {
    fontWeight: "bold",
    paddingHorizontal: 8,
    paddingTop: 8,
  },
  titleContainer: {
    padding: 5,
    borderRadius: 4,
    width: "100%",
  },
  titleText: {},
  keyText: {
    fontSize: 12,
    color: "gray",
  },
  hoveredTitle: {
    backgroundColor: "#f0f0f0",
  },
  noResultsText: {
    paddingHorizontal: 8,
    paddingVertical: 16,
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 22,
  },
});

export default SearchableTextInput;
