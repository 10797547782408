import React, { useState, useEffect, useRef } from "react";
import { setCurrentQuestion } from "../redux/actions/current-question";
import { setCurrentStep } from "../redux/actions/current-step";
import {
  StatusBar,
  StyleSheet,
  ScrollView,
  View,
  Animated,
  Dimensions,
} from "react-native";
import { useDispatch } from "react-redux";
import Header from "../component/Nested/Header";
import Question from "../component/Nested/Question";
import Step from "../component/Nested/Step";
import Footer from "../component/Nested/Footer";
import {
  getKeyQuestion,
  getCorrectId,
  getCorrectAnswer,
} from "../component/utils";
import { loadQuestions } from "../redux/actions/questions";
import {
  storeData,
  getData,
  storeSubjects,
  getSubjects,
} from "../redux/util/data";
import { KEY_DATA_USER } from "../redux/types/user";
import { db } from "../config/firebase";
import { getDoc, doc, updateDoc } from "firebase/firestore";
import { setUser } from "../redux/actions/user";

const hs = Dimensions.get("window").height;

function NestedScreen({
  user,
  currentSubject,
  currentGrade,
  questions,
  currentStep,
  currentQuestion,
  hintPrice,
  firstLoad,
  setFirstLoad,
  navigation,
}) {
  const dispatch = useDispatch();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [selectedStepOption, setSelectedStepOption] = useState();
  const [currentSelect, setCurrentSelect] = useState();
  const scrollViewRef = useRef();

  let AnimatedHeaderValue = new Animated.Value(0);
  const Header_Max_Height = hs * 0.3;
  const Header_Min_Height = 125;

  const animateHeaderHeight = AnimatedHeaderValue.interpolate({
    inputRange: [0, (Header_Max_Height - Header_Min_Height) * 2 + 75],
    outputRange: [Header_Max_Height, Header_Min_Height],
    extrapolate: "clamp",
  });

  const [alertCorrect, setAlertCorrect] = useState(false);
  const [alertWrong, setAlertWrong] = useState(false);

  const createAlert = (answer) => {
    answer === "correct" ? setAlertCorrect(true) : setAlertWrong(true);
    setTimeout(function () {
      answer === "correct" ? setAlertCorrect(false) : setAlertWrong(false);
    }, 1500);
  };

  const checkFailedAttemp = (answers, options) => {
    let failed = false;
    if (answers?.length > 0) {
      for (let k = 0; k < answers?.length; k++) {
        if (answers[k] !== getCorrectId(options)) {
          failed = true;
        }
      }
    }
    return failed;
  };

  const countFailedAttemp = (answers, options) => {
    let count = 0;
    if (answers?.length > 0) {
      for (let k = 0; k < answers?.length; k++) {
        if (answers[k] !== getCorrectId(options)) {
          count++;
        }
      }
    }
    return count;
  };

  async function updateSteps(name, key) {
    const snapData = await getData(currentSubject);
    if (snapData) {
      let totalStepsMastered = 0;
      let totalStepsAttempted = 1;
      for (let i = 0; i < currentQuestion + 1; i++) {
        for (let j = 0; j < currentStep + 1; j++) {
          if (snapData[i].questions[j]?.answer !== undefined) {
            if (
              countFailedAttemp(
                snapData[i].questions[j]?.answer,
                snapData[i].questions[j]?.options
              ) <= 3
            ) {
              totalStepsMastered = totalStepsMastered + 1;
            }
          } else {
            if (
              !checkFailedAttemp(
                snapData[i].questions[j]?.answer,
                snapData[i].questions[j]?.options
              )
            ) {
              totalStepsMastered = totalStepsMastered + 1;
            }
          }
          totalStepsAttempted = totalStepsAttempted + 1;
        }
      }

      try {
        const docRef = doc(db, "class", key);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          for (let i = 0; i < docSnap.data().details.length; i++) {
            if (
              docSnap.data().details[i].name.toLowerCase() ===
              name.toLowerCase()
            ) {
              const current = docSnap.data().details;
              current[i] = {
                ...current[i],
                stepsAttempted: totalStepsAttempted,
                stepsMastered: totalStepsMastered,
              };
              await updateDoc(docRef, {
                details: current,
              });
              break;
            }
          }
        }
      } catch (e) {
        console.log("failed: ", e);
      }
    }
  }

  const addToStartedSubjects = (subject) => {
    let payload = [];
    getSubjects().then((subjects) => {
      if (subjects === null) {
        payload = [subject];
        storeSubjects(payload);
      } else if (subjects?.find((sub) => sub === subject) === undefined) {
        payload = [...subjects, subject];
        storeSubjects(payload);
      }
    });
  };

  const removeFromStartedSubjects = (subject) => {
    let payload = [];
    getSubjects().then((subjects) => {
      payload = subjects.filter((started) => started !== subject);
      storeSubjects(payload);
    });
  };

  const nextStep = () => {
    if (questions !== null) {
      let current = false;
      if (
        questions[currentQuestion].questions[currentStep].answer !== undefined
      ) {
        if (
          questions[currentQuestion].questions[currentStep].answer.length > 0
        ) {
          for (
            let i = 0;
            i < questions[currentQuestion].questions[currentStep].answer.length;
            i++
          ) {
            if (
              questions[currentQuestion].questions[currentStep].answer[i] ===
              getCorrectAnswer(
                questions[currentQuestion].questions[currentStep].options
              )
            ) {
              current = true;
              break;
            }
          }
        }
      }

      const newQuestions = questions.filter((question) => {
        if (question === questions[currentQuestion]) {
          if (
            current ||
            selectedStepOption ===
              getCorrectAnswer(
                questions[currentQuestion].questions[currentStep].options
              )
          ) {
            updateSteps(currentQuestion, currentSubject);
            question.questions[currentStep].isPassed = true;
          }
          question.questions[currentStep].answer !== undefined
            ? (question.questions[currentStep].answer = [
                ...question.questions[currentStep].answer,
                selectedStepOption,
              ])
            : (question.questions[currentStep].answer = [selectedStepOption]);
        }
        return question;
      });
      storeData(currentSubject, newQuestions);

      if (
        current ||
        selectedStepOption ===
          getCorrectAnswer(
            questions[currentQuestion].questions[currentStep].options
          )
      ) {
        setTimeout(function () {
          createAlert("correct");
        }, 0);

        let newUser = user;
        newUser.seed = user.seed + 10;
        dispatch(setUser(newUser));

        setTimeout(function () {
          dispatch(
            setCurrentStep({
              max: questions[currentQuestion].questions.length - 1,
              current: currentStep + 1,
            })
          );
        }, 1000);
        addToStartedSubjects(currentSubject);
      } else {
        setTimeout(function () {
          createAlert("wrong");
        }, 0);
        const handleBuyHint = () => {
          if (
            questions[currentQuestion].questions[currentStep].hints.length > 0
          ) {
            let count = 0;
            for (
              let i = 0;
              i <
              questions[currentQuestion].questions[currentStep].hints.length;
              i++
            ) {
              if (
                questions[currentQuestion].questions[currentStep].hints[i]
                  .isBought === false
              ) {
                function updateData() {
                  const newQuestions = questions.filter((question) => {
                    if (question === questions[currentQuestion]) {
                      question.questions[currentStep].hints[i].isBought = true;
                    }
                    return question;
                  });

                  storeData(currentSubject, newQuestions);
                  const data = getData(currentSubject);
                  data.then((val) => {
                    dispatch(loadQuestions(val));
                  });
                }
                if (i === 0) {
                  updateData();
                } else {
                  if (user.seed < hintPrice) {
                    console.log("insufficient seed");
                  } else {
                    updateData();
                    let newUser = user;
                    newUser.seed = user.seed - 10;
                    dispatch(setUser(newUser));
                  }
                }

                break;
              } else {
                count++;
                if (
                  count ===
                  questions[currentQuestion].questions[currentStep].hints.length
                ) {
                  console.log("no more hints");
                }
              }
            }
          } else {
            console.log("no available hints");
          }
        };
        setTimeout(function () {
          handleBuyHint();
        }, 0);

        createAlert("wrong");

        // const data = getData(getKeyQuestion(currentGrade));
        const data = getData(currentSubject);
        data.then((val) => {
          dispatch(loadQuestions(val));
        });
      }

      setSelectedStepOption(null);
      setCurrentSelect();
    }
  };

  const nextQuestion = () => {
    const newQuestions = questions.filter((question) => {
      if (question === questions[currentQuestion]) {
        question.isPassed = true;
        for (let i = 0; i < question.questions.length; i++) {
          if (question.questions[i].isSkipped === true) {
            question.isSkipped = true;
          } else {
            question.isSkipped = false;
          }
        }
      }
      return question;
    });
    // storeData(getKeyQuestion(currentGrade), newQuestions);
    storeData(currentSubject, newQuestions);

    if (currentQuestion >= questions.length - 1) {
      setTimeout(function () {
        dispatch(
          setCurrentQuestion({
            max: questions.length - 1,
            current: questions.length - 1,
          })
        );
        dispatch(
          setCurrentStep({
            max: questions[currentQuestion].questions.length - 1,
            current: questions[currentQuestion].questions.length - 1,
          })
        );
        removeFromStartedSubjects(currentSubject);
      }, 1500);
    } else {
      setTimeout(function () {
        dispatch(
          setCurrentQuestion({
            max: questions.length - 1,
            current: currentQuestion + 1,
          })
        );
        dispatch(
          setCurrentStep({
            max: questions[currentQuestion].questions.length - 1,
            current: 0,
          })
        );
      }, 1500);
    }
  };

  useEffect(() => {
    if (questions !== null) {
      const getCurrentQuestion = () => {
        let countPassed = 0;

        for (let i = 0; i < questions.length; i++) {
          if (questions[i].isPassed) {
            countPassed++;
          }
        }

        for (let i = 0; i < questions.length; i++) {
          if (countPassed === questions.length) {
            let wrong = 0;
            for (let j = 0; j < questions[i].questions.length; j++) {
              if (
                checkFailedAttemp(
                  questions[i].questions[j].answer,
                  questions[i].questions[j].options
                )
              ) {
                wrong =
                  wrong +
                  countFailedAttemp(
                    questions[i].questions[j].answer,
                    questions[i].questions[j].options
                  );
              }
            }

            if (wrong > 3) {
              const resetGrade = () => {
                const newQuestions = questions.filter((question) => {
                  if (question === questions[i]) {
                    questions[i].isPassed = false;
                    for (let j = 0; j < questions[i].questions.length; j++) {
                      question.questions[j].answer = [];
                      question.questions[j].isPassed = false;
                      for (
                        let k = 0;
                        k < questions[i].questions[j].hints.length;
                        k++
                      ) {
                        question.questions[j].hints[k].isBought = false;
                      }
                    }
                  }
                  return question;
                });
                // storeData(getKeyQuestion(currentGrade), newQuestions);
                // setData(KEY_DATA_USER, getKeyQuestion(currentGrade));
                storeData(getKeyQuestion(currentSubject), newQuestions);
                setData(KEY_DATA_USER, getKeyQuestion(currentSubject));
              };
              const setData = async (keyDataUser, keyDataQuestions) => {
                try {
                  const data = getData(keyDataQuestions);
                  data.then((val) => {
                    dispatch(loadQuestions(val));
                    dispatch(
                      setCurrentStep({
                        max: 0,
                        current: 0,
                      })
                    );
                    dispatch(
                      setCurrentQuestion({
                        max: questions.length - 1,
                        current: i,
                      })
                    );
                  });
                } catch (error) {
                  console.log(error.message);
                }
              };
              resetGrade();
              setFirstLoad(false);
            }
          } else if (questions[i].isPassed === true) {
            if (i === questions.length - 1) {
              dispatch(
                setCurrentStep({
                  max: questions[i].questions.length - 1,
                  current: questions[i].questions.length - 1,
                })
              );
              dispatch(
                setCurrentQuestion({
                  max: questions.length - 1,
                  current: i + 1,
                })
              );
            }
          } else {
            dispatch(
              setCurrentQuestion({
                max: questions.length - 1,
                current: i,
              })
            );

            for (let j = 0; j <= questions[i].questions.length - 1; j++) {
              if (questions[i].questions[j].isPassed === true) {
                if (currentStep < questions[i].questions.length - 1) {
                  dispatch(
                    setCurrentStep({
                      max: questions[i].questions.length - 1,
                      current: j + 1,
                    })
                  );
                } else {
                  dispatch(
                    setCurrentStep({
                      max: questions[i].questions.length - 1,
                      current: questions[currentQuestion].questions.length - 1,
                    })
                  );
                }
              } else {
                dispatch(
                  setCurrentStep({
                    max: questions[i].questions.length - 1,
                    current: j,
                  })
                );
                break;
              }
            }
            break;
          }
        }
        setDataLoaded(true);
      };

      const tryAgain = () => {
        for (let i = 0; i < questions.length; i++) {
          if (questions[i].isPassed) {
          }
        }
      };

      const onPageLoad = () => {
        if (dataLoaded === true) {
          getCurrentQuestion();
        } else {
          console.log("question loading");
        }
      };

      if (document.readyState === "complete") {
        onPageLoad();
        setDataLoaded(true);
      } else {
        window.addEventListener("load", onPageLoad, false);
        const setData = async (keyDataUser, keyDataQuestions) => {
          try {
            const data = getData(keyDataQuestions);
            data.then((val) => {
              questions = val;
            });
          } catch (error) {
            console.log(error.message);
          }
        };
        getCurrentQuestion();
        return () => window.removeEventListener("load", onPageLoad);
      }
    }
  }, [
    dataLoaded,
    currentSubject,
    currentGrade,
    currentQuestion,
    currentStep,
    questions,
  ]);

  useEffect(() => {
    if (currentSelect !== undefined && questions !== null) {
      nextStep();
    }
  }, [currentSelect]);

  useEffect(() => {
    if (questions !== null) {
      if (
        questions[currentQuestion].questions.length - 1 === currentStep &&
        questions[currentQuestion].questions[currentStep].isPassed
      ) {
        nextQuestion();
      }
    }
  }, [questions?.[currentQuestion]?.questions?.[currentStep]]);

  return (
    <>
      <Header
        user={user}
        questions={questions}
        currentSubject={currentSubject}
        currentGrade={currentGrade}
        currentQuestion={currentQuestion}
        navigation={navigation}
      />
      <Question
        currentSubject={currentSubject}
        currentGrade={currentGrade}
        questions={questions}
        currentQuestion={currentQuestion}
        currentStep={currentStep}
      />
      <ScrollView
        scrollEventThrottle={16}
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { y: AnimatedHeaderValue } } }],
          { useNativeDriver: false }
        )}
        showsVerticalScrollIndicator={false}
        ref={scrollViewRef}
        onContentSizeChange={() =>
          scrollViewRef.current.scrollToEnd({ animated: true })
        }
      >
        <View style={styles.body}>
          <Step
            user={user}
            currentSubject={currentSubject}
            currentGrade={currentGrade}
            questions={questions}
            currentQuestion={currentQuestion}
            currentStep={currentStep}
            selectedStepOption={selectedStepOption}
            setSelectedStepOption={setSelectedStepOption}
            currentSelect={currentSelect}
            setCurrentSelect={setCurrentSelect}
            alertCorrect={alertCorrect}
            alertWrong={alertWrong}
          />
        </View>
      </ScrollView>
      <Footer
        user={user}
        questions={questions}
        currentQuestion={currentQuestion}
        currentStep={currentStep}
        currentGrade={currentGrade}
        currentSubject={currentSubject}
        hintPrice={hintPrice}
      />
    </>
  );
}

export default NestedScreen;

const styles = StyleSheet.create({
  container: {
    paddingTop: StatusBar.currentHeight,
    backgroundColor: "#FAFAFC",
  },
  body: {
    paddingHorizontal: 16,
    minHeight: hs * 0.3,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginHorizontal: 28,
    marginVertical: 32,
  },
  alertBoxCorrect: {
    height: "75px",
    width: "75px",
    position: "absolute",
    zIndex: 2,
    right: "64px",
    bottom: "-14px",
    justifyContent: "center",
  },
  alertBoxWrong: {
    height: "75px",
    width: "75px",
    position: "absolute",
    zIndex: 2,
    right: "64px",
    bottom: "-14px",
    justifyContent: "center",
  },
  alertCorrect: {
    height: "75px",
    width: "75px",
    alignSelf: "center",
  },
  alertWrong: {
    height: "75px",
    width: "75px",
    alignSelf: "center",
  },
});
