import React from "react";
import { StyleSheet, View, Dimensions, Image } from "react-native";
import Hint from "./FooterElement/Hint";
// import Next from "./FooterElement/Next";
const hs = Dimensions.get("window").height;
function Footer({
  user,
  questions,
  currentQuestion,
  currentStep,
  currentGrade,
  hintPrice,
  currentSubject,
}) {
  return (
    <View style={styles.row}>
      <Hint
        user={user}
        questions={questions}
        currentQuestion={currentQuestion}
        currentStep={currentStep}
        currentGrade={currentGrade}
        currentSubject={currentSubject}
        hintPrice={hintPrice}
      />

      {/* <Next
        userData={user}
        questions={questions}
        currentQuestion={currentQuestion}
        currentStep={currentStep}
        currentGrade={currentGrade}
        selectedStepOption={selectedStepOption}
        setSelectedStepOption={setSelectedStepOption}
        hintPrice={hintPrice}
        createAlert={createAlert}
        setCurrentSelect={setCurrentSelect}
        currentSubject={currentSubject}
        pressEnd={pressEnd}
        setPressEnd={setPressEnd}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      /> */}
    </View>
  );
}

export default Footer;

const styles = StyleSheet.create({
  row: {
    position: "absolute",
    bottom: 0,
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "white",
    width: "100%",
    alignItems: "center",
    paddingVertical: 16,
    paddingHorizontal: 26,
  },
});
