import React, { useEffect, useState, useContext } from "react";
import {
  StyleSheet,
  View,
  Text,
  Image,
  ScrollView,
  Dimensions,
  Pressable,
  Button,
  FlatList,
} from "react-native";
import { storeData, getData } from "../redux/util/data";
import { KEY_DATA_USER } from "../redux/types/user";
import initDataUser from "../config/user.json";
import SubjectButton from "../component/HomePage/SubjectButton";
import subjects from "../config/subjects.json";
import Cat from "../component/JoinClassPage/Cat";
import Seed from "../component/JoinClassPage/Seed";
import { auth } from "../config/firebase";
import { signOut } from "@firebase/auth";
import DataContext from "../component/ContextProvider/DataContext";
import { logEvent } from "firebase/analytics";
import { analytics } from "../config/firebase";
import JoinClass from "../component/JoinClassPage/JoinClass";
import GrapeButton from "../component/JoinClassPage/GrapeButton";

const ws = Dimensions.get("window").width;
const ratio = (ws - 15) / 1905 / 1.1;
const cratio = (ws - 15) / 1280 / 1.1;
const pratio = (ws - 15) / 660 / 1.1;

function JoinClassPage({ navigation }) {
  const { user, loginWithGoogle, logout } = useContext(DataContext);
  const [userData, setUserData] = useState();

  const [widthBackground1, setWidthBackground1] = useState(0);
  const [heightBackground1, setHeightBackground1] = useState(0);
  const [widthBackground2, setWidthBackground2] = useState(0);
  const [heightBackground2, setHeightBackground2] = useState(0);
  const [loading, setLoading] = useState(true);
  logEvent(analytics, "page_view", {
    page_title: `Join class page`,
  });

  useEffect(() => {
    let isMounted = true;

    const updateData = () => {
      getData(KEY_DATA_USER).then((val) => {
        if (val) {
          setUserData(val);
        } else {
          storeData(KEY_DATA_USER, initDataUser);
          setUserData(initDataUser);
        }
      });
    };
    if (isMounted) {
      updateData();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    const getSize = () => {
      Image.getSize("assets/background_desktop_1.png", (width, height) => {
        setWidthBackground1(width);
        setHeightBackground1(height);
      });
      Image.getSize("assets/background_desktop_2.png", (width, height) => {
        setWidthBackground2(width);
        setHeightBackground2(height);
      });
    };
    if (loading) {
      getSize();
    }
    return () => {
      setLoading(false);
    };
  }, []);

  return (
    <View style={{ height: "100%", width: "100%" }}>
      <Image
        style={[
          styles.background,
          { height: heightBackground1, width: "100%" },
        ]}
        source={"assets/background_desktop_1.png"}
        height={heightBackground1}
      />
      <Image
        style={[
          styles.background_bottom,
          { height: heightBackground2, width: "100%" },
        ]}
        source={"assets/background_desktop_2.png"}
      />
      <View style={styles.header}>
        <View style={styles.rowHeader}>
          <View style={styles.logoContainer}>
            <GrapeButton />
            <Text style={styles.textLogo}>Grape Assignments</Text>
          </View>
          <View style={[styles.logoContainer]}>
            {userData && <Seed user={userData} />}
            <Cat
              navigation={navigation}
              user={user}
              userData={userData}
              loginWithGoogle={loginWithGoogle}
              logout={logout}
            />
          </View>
        </View>
      </View>
      <ScrollView>
        <View style={styles.container}>
          <View style={styles.row}>
            {/* <Pressable
              onPress={() => navigation.navigate("/")}
              style={{ backgroundColor: "#D3D3D3", padding: 8 }}
            >
              <Text>BACK</Text>
            </Pressable> */}
          </View>
          <JoinClass navigation={navigation} />
        </View>
      </ScrollView>
    </View>
    // <>
    //   <View style={styles.row}>
    //     <Pressable
    //       onPress={() => navigation.navigate("/")}
    //       style={{ backgroundColor: "#D3D3D3", padding: 8 }}
    //     >
    //       <Text>BACK</Text>
    //     </Pressable>
    //   </View>
    //   <JoinClass navigation={navigation} />
    // </>
  );
}

export default JoinClassPage;

const styles = StyleSheet.create({
  // row: {
  //   flexDirection: "row",
  //   alignItems: "center",
  //   justifyContent: "space-between",
  // },
  background: {
    position: "absolute",
    top: 0,
    zIndex: -1,
  },
  background_bottom: {
    position: "absolute",
    bottom: 0,
    zIndex: -1,
  },
  container: {
    paddingHorizontal:
      ws > 1295 ? 32 * ratio : ws > 675 ? 32 * cratio : 32 * pratio,
    alignItems: "center",
    marginTop: 150,
  },
  header: {
    marginBottom: 16,
    padding: 16,
    width: "100%",
  },
  row: {
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
    paddingVertical:
      ws > 1295 ? 16 * ratio : ws > 675 ? 16 * cratio : 16 * pratio,
    gap: ws > 1295 ? 30 * ratio : ws > 675 ? 30 * cratio : 30 * pratio,
  },
  rowHeader: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  logoContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: ws <= 480 ? 0 : 32,
    flexWrap: "wrap",
  },
  logo: {
    width: 40,
    height: 40,
    resizeMode: "contain",
  },
  textLogo: {
    fontSize: ws <= 480 ? 24 : 32,
    marginLeft: 8,
  },
});
