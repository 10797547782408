import React from "react";
// import ImageHolder from "./ImageHolder";
import { StyleSheet, View, Text } from "react-native";
import VideoInput from "../../ProjectBased/StepElement/VideoInput";

import { getImage, formatQuestion, getVideo } from "../../utils";

function HintBox(props) {
  return (
    <View style={styles.container}>
      {props.hints.length >= 1 ? (
        props.hints.map((hint, index) => (
          <View
            key={`hint${index}`}
            style={{ width: "100%", paddingTop: "20px" }}
          >
            <View style={{ justifyContent: "left" }}>
              <Text style={styles.title}>{`Hint ${index + 1}`}</Text>
              <Text style={styles.hintDescription}>
                {formatQuestion(hint ?? "")}
              </Text>
              {/* {getImage(hint) && <ImageHolder path={getImage(hint)} />} */}
              {getVideo(hint) && (
                <VideoInput width={200} height={80} videoSrc={getVideo(hint)} />
              )}
            </View>
          </View>
        ))
      ) : (
        <View>
          <Text></Text>
        </View>
      )}
    </View>
  );
}

export default HintBox;

const styles = StyleSheet.create({
  title: {
    fontWeight: "400",
    fontSize: "90%",
    color: "#530691",
  },
  item: {
    marginVertical: 8,
    borderRadius: 8,
    borderColor: "#D3D3D3",
    backgroundColor: "#fff",
    shadowColor: "#AE66E4",
    shadowOffset: { width: 1, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 4,
    marginHorizontal: 16,
  },
  container: { width: "80%", paddingTop: "10px", marginHorizontal: "auto" },
  hintDescription: {
    fontWeight: "400",
    fontSize: "120%",
    color: "#1D232E",
    lineHeight: "120%",
  },
});
