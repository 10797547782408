import React from "react";
import { StyleSheet, View, Text, Image } from "react-native";
import { formatBreakLine, randomCelebration } from "../../utils";
import HintBox from "./HintBox";
import Options from "./Options";

function ItemStep({
  item,
  user,
  questions,
  currentGrade,
  currentQuestion,
  currentStep,
  selectedStepOption,
  setSelectedStepOption,
  currentSelect,
  setCurrentSelect,
  alertCorrect,
  alertWrong,
}) {
  return (
    <View style={styles.step} key={item.key}>
      <Text style={styles.title}>{`Step ${item.step + 1}`}</Text>
      <Text style={styles.question}>{formatBreakLine(item.question)}</Text>
      {currentStep === item.step && alertCorrect && (
        <View style={styles.alertBox}>
          <Image style={styles.alert} source={randomCelebration("correct")} />
        </View>
      )}
      {currentStep === item.step && alertWrong && (
        <View style={styles.alertBox}>
          <Image style={styles.alert} source={randomCelebration("wrong")} />
        </View>
      )}
      <View style={styles.row}>
        <Options
          item={item}
          user={user}
          questions={questions}
          currentGrade={currentGrade}
          currentQuestion={currentQuestion}
          currentStep={currentStep}
          selectedStepOption={selectedStepOption}
          setSelectedStepOption={setSelectedStepOption}
          currentSelect={currentSelect}
          setCurrentSelect={setCurrentSelect}
        />
        <HintBox hints={item.hints} />
      </View>
    </View>
  );
}

export default ItemStep;

const styles = StyleSheet.create({
  step: {
    marginTop: 16,
  },
  title: {
    fontWeight: "400",
    fontSize: "120%",
    color: "#A25ADC",
  },
  question: {
    paddingTop: 4,
    paddingBottom: 24,
    fontWeight: "400",
    fontSize: "120%",
    color: "#1D232E",
    lineHeight: "120%",
  },
  row: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    marginBottom: 32,
    // flex: ws > 960 ? 3 : ws > 600 ? 2 : 1,
    // padding: 8,
    // paddingHorizontal: 32,
    // minHeight: 60
  },
  alertBox: {
    height: "100px",
    width: "100px",
    position: "absolute",
    zIndex: 2,
    right: "49%",
    top: "0px",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  alert: {
    height: "72px",
    width: "72px",
    alignSelf: "center",
  },
});
