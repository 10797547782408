import React, { useContext, useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  Text,
  Linking,
  Pressable,
  Dimensions,
  ScrollView,
} from "react-native";
import { getData, storeData } from "../redux/util/data";
import { KEY_DATA_USER } from "../redux/types/user";
import initDataUser from "../config/user.json";
import hintsIndex from "../config/hintGalleryIndex.json";
import DataContext from "../component/ContextProvider/DataContext";
import Cat from "../component/HomePage/Cat";
import Seed from "../component/HomePage/Seed";
import Background from "../component/Nested/HeaderElement/Background";
import GrapeButton from "../component/Nested/HeaderElement/GrapeButton";
import VideoInput from "../component/ProjectBased/StepElement/VideoInput";
import { logEvent } from "firebase/analytics";
import { analytics } from "../config/firebase";

const ws = Dimensions.get("window").width;
const ratio = (ws - 15) / 1905 / 1.1;
const cratio = (ws - 15) / 1280 / 1.1;
const pratio = (ws - 15) / 660 / 1.1;

function getVideo(video) {
  let result = "";

  if (video) {
    result = "assets/hints/MP4s/" + video + ".mp4";
  } else {
    result = false;
  }

  return result;
}

const HintBox = ({ text, video }) => (
  <View style={styles.hintBox}>
    <Text style={styles.hintText}>{text}</Text>
    {getVideo(video) && <VideoInput videoSrc={getVideo(video)} />}
  </View>
);

function HintGalleryPage({ navigation, route }) {
  const { user, loginWithGoogle, logout } = useContext(DataContext);
  const [userData, setUserData] = useState();
  const [hints, setHints] = useState([]);
  logEvent(analytics, "page_view", {
    page_title: `Hints gallery page - ${route.params?.subject}`,
  });
  useEffect(() => {
    let isMounted = true;

    const updateData = () => {
      getData(KEY_DATA_USER).then((val) => {
        if (val) {
          setUserData(val);
        } else {
          storeData(KEY_DATA_USER, initDataUser);
          setUserData(initDataUser);
        }
      });
    };
    if (isMounted) {
      updateData();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (route.params.subject) {
      setHints(
        hintsIndex.filter((hint) => hint.subject === route.params.subject)
      );
    }
  }, [route]);

  return (
    <View style={styles.flex}>
      <View style={styles.headerContainer}>
        <Background />
        <View style={styles.rowHeader}>
          <GrapeButton navigation={navigation} />
          <Text style={styles.textLogo}>
            {route.params?.subject} Hint Gallery
          </Text>
          <View style={styles.flex}>
            <View style={styles.rightSection}>
              {userData && <Seed user={userData} />}
              <Cat
                navigation={navigation}
                user={user}
                userData={userData}
                loginWithGoogle={loginWithGoogle}
                logout={logout}
              />
            </View>
          </View>
        </View>
      </View>
      <ScrollView contentContainerStyle={styles.container}>
        <Text style={styles.text}>
          Grape Assignments tries to help struggling learners break down a
          mathematical story problem into steps. Many steps have hints and many
          hints have short whiteboard explanations we made using{" "}
          <Pressable
            onPress={() => Linking.openURL("https://whiteboardgifs.com/")}
          >
            <Text style={styles.textLink}>WhiteboardGIFs.com</Text>
          </Pressable>
          .
        </Text>
        <Text style={styles.text}>
          We'd very much appreciate{" "}
          <Pressable
            onPress={() =>
              Linking.openURL(
                "mailto:richkingsford@whiteboardGIFs.com?subject=Mathematical%20hints%20feedback"
              )
            }
          >
            <Text style={styles.textLink}>your feedback</Text>
          </Pressable>{" "}
          on better hints or more hints.
        </Text>
        <View style={styles.hintsContainer}>
          {hints.map(({ text, video }, index) => (
            <HintBox key={index} text={text} video={video} />
          ))}
        </View>
      </ScrollView>
    </View>
  );
}

export default HintGalleryPage;

const styles = StyleSheet.create({
  rowHeader: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  textLogo: {
    fontSize: ws <= 480 ? 22 : 28,
    color: "#FFF",
  },
  headerContainer: {
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
    paddingHorizontal: 16,
    paddingVertical: 24,
    backgroundColor: "#A25ADC",
    overflow: "hidden",
    marginBottom: 16,
  },
  rightSection: {
    flexDirection: "row",
    justifyContent: "right",
    alignItems: "center",
    gap: ws <= 480 ? 6 : 24,
  },
  flex: {
    flex: 1,
  },
  container: {
    paddingHorizontal:
      ws > 1295 ? 32 * ratio : ws > 675 ? 32 * cratio : 32 * pratio,
  },
  text: {
    fontSize: ws <= 480 ? 16 : 20,
    marginBottom: 16,
  },
  textLink: {
    color: "#0645AD",
    textDecorationLine: "underline",
  },
  hintsContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    flexWrap: "wrap",
  },
  hintBox: {
    paddingHorizontal: 16,
    paddingVertical: 24,
    border: "2px solid #D3D3D3",
    borderRadius: 8,
    width: ws <= 480 ? "100%" : ws <= 1080 ? ws * 0.44 : ws * 0.46,
    textAlign: "center",
    marginVertical: 16,
    marginHorizontal: 5,
    overflow: "hidden",
  },
  hintText: {
    fontSize: ws <= 480 ? 16 : ws <= 780 ? 18 : 20,
    marginBottom: 16,
  },
});
