import React from "react";
import { StyleSheet, View, Dimensions, Image } from "react-native";
import Options from "./StepElement/Options";
import HintBox from "./StepElement/HintBox";
import { randomCelebration } from "../utils";

const ws = Dimensions.get("window").width;

function StandaloneOptions({
  user,
  questions,
  currentGrade,
  currentQuestion,
  item,
  currentStep,
  setSelectedStepOption,
  selectedStepOption,
  currentSelect,
  setCurrentSelect,
  pressEnd,
  setPressEnd,
  modalVisible,
  setModalVisible,
  alertCorrect,
  alertWrong,
}) {
  return (
    <>
      <View style={styles.alertContainer}>
        {alertCorrect && (
          <View style={styles.alertBox}>
            <Image style={styles.alert} source={randomCelebration("correct")} />
          </View>
        )}
        {alertWrong && (
          <View style={styles.alertBox}>
            <Image style={styles.alert} source={randomCelebration("wrong")} />
          </View>
        )}
      </View>
      <View style={styles.row}>
        <Options
          item={item}
          user={user}
          questions={questions}
          currentGrade={currentGrade}
          currentQuestion={currentQuestion}
          currentStep={currentStep}
          selectedStepOption={selectedStepOption}
          setSelectedStepOption={setSelectedStepOption}
          currentSelect={currentSelect}
          setCurrentSelect={setCurrentSelect}
          pressEnd={pressEnd}
          setPressEnd={setPressEnd}
          modalVisible={modalVisible}
          setModalVisible={setModalVisible}
        />
        <HintBox hints={item.hints} />
      </View>
    </>
  );
}

export default StandaloneOptions;
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    marginBottom: 32,
    paddingHorizontal: 16,
    paddingTop: 40,
    paddingBottom: 100,
    // flex: ws > 960 ? 3 : ws > 600 ? 2 : 1,
    // paddingHorizontal: 32,
    // minHeight: 60
  },
  alertContainer: {
    alignItems: "center",
    position: "relative",
  },
  alertBox: {
    height: "64px",
    width: "64px",
    position: "absolute",
    zIndex: 5,
    justifyContent: "center",
  },
  alert: {
    height: "56px",
    width: "56px",
    alignSelf: "center",
  },
});
