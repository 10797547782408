import React, { useEffect, useState, useContext, useRef } from "react";
import {
  StyleSheet,
  View,
  Text,
  Image,
  ScrollView,
  Dimensions,
  Pressable,
  Button,
  FlatList,
  Linking,
  TouchableOpacity,
} from "react-native";
import { storeData, getData, getSubjects } from "../redux/util/data";
import { KEY_DATA_USER } from "../redux/types/user";
import initDataUser from "../config/user.json";
import SubjectButton from "../component/HomePage/SubjectButton";
import subjects from "../config/subjects.json";
import Cat from "../component/HomePage/Cat";
import Seed from "../component/HomePage/Seed";
import { analytics, auth } from "../config/firebase";
import { signOut } from "@firebase/auth";
import DataContext from "../component/ContextProvider/DataContext";
import { useDispatch } from "react-redux";
import { setCurrentSubject } from "../redux/actions/current-subject";
import { logEvent } from "firebase/analytics";

const ws = Dimensions.get("window").width;
const ratio = (ws - 15) / 1905 / 1.1;
const cratio = (ws - 15) / 1280 / 1.1;
const pratio = (ws - 15) / 660 / 1.1;

function groupByTitle(data) {
  return data.reduce((acc, curr) => {
    const x = acc.find((item) => item.title === curr.title);
    if (!x) {
      return acc.concat([curr]);
    } else {
      return acc;
    }
  }, []);
}

function HomePage({ navigation }) {
  const { user, loginWithGoogle, logout } = useContext(DataContext);
  const [userData, setUserData] = useState();
  const [userSubjects, setUserSubjects] = useState([]);

  logEvent(analytics, "page_view", {
    page_title: "Homepage",
  });
  const [widthBackground1, setWidthBackground1] = useState(0);
  const [heightBackground1, setHeightBackground1] = useState(0);
  const [widthBackground2, setWidthBackground2] = useState(0);
  const [heightBackground2, setHeightBackground2] = useState(0);
  const [loading, setLoading] = useState(true);
  const [hoveredLeft, setHoveredLeft] = useState(-1);
  const [pressedLeft, setPressedLeft] = useState(-1);
  const [hoveredRight, setHoveredRight] = useState(-1);
  const [pressedRight, setPressedRight] = useState(-1);
  const [contHoveredLeft, setContHoveredLeft] = useState(false);
  const [contPressedLeft, setContPressedLeft] = useState(false);
  const [contHoveredRight, setContHoveredRight] = useState(false);
  const [contPressedRight, setContPressedRight] = useState(false);
  const dispatch = useDispatch();

  const ref = useRef(new Array());
  const contRef = useRef();

  const [currentIndex, setCurrentIndex] = useState(new Array());
  const [currentOffset, setCurrentOffset] = useState(new Array());
  const [contIndex, setContIndex] = useState(0);

  const handleScroll = (index, offset) => {
    let newOffset = currentOffset;
    newOffset[index] = offset;
    setCurrentOffset([newOffset]);
    const r = (ws <= 420 ? 420 - 15 : ws <= 874 ? 1024 : ws - 15) / 1905 / 1.1;
    const cr = (ws <= 420 ? 420 - 15 : ws <= 874 ? 1024 : ws - 15) / 1280 / 1.1;
    const n = ws > 1295 ? 590 * r : 590 * cr;

    let newIndex = currentIndex;
    newIndex[index] = parseInt(offset / n);
    setCurrentIndex(newIndex);
  };

  const handleContinueMasteringScroll = (offset) => {
    const r = (ws <= 420 ? 420 - 15 : ws <= 874 ? 1024 : ws - 15) / 1905 / 1.1;
    const cr = (ws <= 420 ? 420 - 15 : ws <= 874 ? 1024 : ws - 15) / 1280 / 1.1;
    const n = ws > 1295 ? 590 * r : 590 * cr;

    let newIndex = parseInt(offset / n);
    setContIndex(newIndex);
  };

  function findAttemptedSubjects(subjectsArr, subjectKeysArray) {
    const matchingItems = [];

    if (subjectKeysArray !== null) {
      for (const subject of subjectsArr) {
        for (const item of subject.data) {
          if (subjectKeysArray.includes(item.key)) {
            matchingItems.push(item);
          }
        }
      }
    }

    return matchingItems;
  }

  useEffect(() => {
    let isMounted = true;

    const updateData = () => {
      const idxs = [];
      for (let i = 0; i < subjects.length; i++) {
        idxs.push(0);
      }
      setCurrentIndex(idxs);
    };
    if (isMounted) {
      updateData();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;

    const updateData = () => {
      getData(KEY_DATA_USER).then((val) => {
        if (val) {
          setUserData(val);
        } else {
          storeData(KEY_DATA_USER, initDataUser);
          setUserData(initDataUser);
        }
      });
      getSubjects().then((res) => {
        const matchingSubjects = findAttemptedSubjects(subjects, res);
        setUserSubjects(matchingSubjects);
      });
    };
    if (isMounted) {
      updateData();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    const getSize = () => {
      Image.getSize("assets/background_desktop_1.png", (width, height) => {
        setWidthBackground1(width);
        setHeightBackground1(height);
      });
      Image.getSize("assets/background_desktop_2.png", (width, height) => {
        setWidthBackground2(width);
        setHeightBackground2(height);
      });
    };
    if (loading) {
      getSize();
    }
    return () => {
      setLoading(false);
    };
  }, []);

  useEffect(() => {
    if (loading) {
      const params = new URLSearchParams(window.location.search);
      // const path = window.location.pathname;
      const title = params.get("title");
      const key = params.get("key");
      const destination = params.get("destination");
      // console.log("path", path);
      if (title && key && destination) {
        setTimeout(() => {
          dispatch(setCurrentSubject({ subject: key }));
          navigation.navigate(destination, {
            name: title,
            subject: key,
          });
        }, 0);
      }
    }
  }, [loading]);

  return (
    <View style={{ height: "100%", width: "100%" }}>
      <Image
        style={[
          styles.background,
          { height: heightBackground1, width: "100%" },
        ]}
        source={"assets/background_desktop_1.png"}
        height={heightBackground1}
      />
      <Image
        style={[
          styles.background_bottom,
          { height: heightBackground2, width: "100%" },
        ]}
        source={"assets/background_desktop_2.png"}
      />
      <View style={styles.header}>
        <View style={styles.rowHeader}>
          <View style={styles.logoContainer}>
            <Image source={"assets/square_logo.svg"} style={styles.logo} />
            <Text style={styles.textLogo}>Grape Assignments</Text>
          </View>
          <View style={[styles.logoContainer]}>
            {userData && <Seed user={userData} />}
            <Cat
              navigation={navigation}
              user={user}
              userData={userData}
              loginWithGoogle={loginWithGoogle}
              logout={logout}
            />
          </View>
        </View>
      </View>
      <ScrollView>
        <View style={styles.container}>
          <Pressable
            style={styles.greenCard}
            onPress={() => {
              navigation.navigate("SetupClassPage");
            }}
          >
            <View style={{ flexDirection: "row", gap: 64 }}>
              <View>
                <Text style={styles.greenSubject}>
                  {" "}
                  <h1
                    style={{
                      marginTop: ws > 1000 ? 10 : (10 * ws) / 1000,
                    }}
                  >
                    Student projects and learning activities
                  </h1>
                </Text>
                <View style={styles.greenContent}>
                  <View
                    style={{
                      flex: ws > 700 && 0.6,
                      alignItems: "left",
                      height: "100%",
                    }}
                  >
                    <View>
                      <Text style={styles.cardH2}>Automatically graded</Text>
                      <Text style={styles.cardH2}>Learn at my own pace</Text>
                      <Text style={styles.cardH2}>
                        Supplemental or core curriculum
                      </Text>
                      <Text
                        style={[
                          styles.cardH2,
                          { marginBottom: ws > 1000 ? 23 : (23 * ws) / 1000 },
                        ]}
                      >
                        Whiteboard animations
                      </Text>
                    </View>
                    <Pressable
                      onPress={() => {
                        navigation.navigate("SetupClassPage");
                      }}
                      style={(state) => [
                        styles.classBtn,
                        {
                          backgroundColor: state.hovered
                            ? "#2c1143"
                            : "#5a4bad",
                        },
                      ]}
                    >
                      <Text style={styles.btnText}>
                        SET UP A CLASS FOR FREE
                      </Text>
                    </Pressable>
                  </View>
                </View>
              </View>
              <View
                style={{ flex: 0.4, alignItems: "flex-end", height: "100%" }}
              >
                <Image
                  style={styles.teacherImage}
                  source={"assets/college_student_or_teacher_172Height.png"}
                />
              </View>
            </View>
          </Pressable>

          {userSubjects.length > 0 && (
            <View>
              <Text style={styles.title}>Continue mastering</Text>
              <View
                style={{
                  width: ws,
                  flexDirection: "row",
                }}
              >
                {ws > 950 && (
                  <TouchableOpacity
                    style={[
                      styles.navButton,
                      {
                        opacity: contHoveredLeft
                          ? 0.5
                          : contPressedLeft
                          ? 1
                          : 0,
                      },
                    ]}
                    onPress={() => {
                      if (contIndex > 0) {
                        let newIndex = contIndex - 1;
                        contRef.current?.scrollToIndex({
                          index: newIndex,
                          animated: true,
                        });
                        setContIndex(newIndex);
                      }
                    }}
                    onMouseEnter={() => setContHoveredLeft(true)}
                    onMouseLeave={() => setContHoveredLeft(false)}
                    onPressIn={() => setContPressedLeft(true)}
                    onPressOut={() => setContPressedLeft(false)}
                    activeOpacity={1}
                  >
                    <Image
                      source={"assets/chevron.png"}
                      style={[
                        styles.arrowButton,
                        { transform: [{ scaleX: -1 }] },
                      ]}
                    />
                  </TouchableOpacity>
                )}
                <FlatList
                  ref={contRef}
                  onScroll={(event) => {
                    handleContinueMasteringScroll(
                      event.nativeEvent.contentOffset.x
                    );
                  }}
                  scrollEventThrottle={16}
                  data={groupByTitle(userSubjects)}
                  keyExtractor={(item, index) => index.toString()}
                  renderItem={({ item, index: fIndex }) => {
                    const keysAndDestinations = userSubjects
                      .filter((data) => data.title === item.title)
                      .map((data) => ({
                        key: data.key,
                        destination: data.destination,
                        totalQuestions: data.question_count,
                      }));

                    return (
                      <SubjectButton
                        title={item.title.replaceAll("-", " ")}
                        subject={item.key}
                        iconName={item.icon}
                        navigation={navigation}
                        keysAndDestinations={keysAndDestinations}
                      />
                    );
                  }}
                  horizontal
                  showsHorizontalScrollIndicator={false}
                  style={{ flex: 1, maxWidth: ws }}
                  contentContainerStyle={{
                    paddingVertical: 4,
                    paddingHorizontal: 32,
                    marginBottom: 8,
                  }}
                />
                {ws > 950 && (
                  <TouchableOpacity
                    style={[
                      styles.navButton,
                      {
                        opacity: contHoveredRight
                          ? 0.5
                          : contPressedRight
                          ? 1
                          : 0,
                      },
                    ]}
                    onPress={() => {
                      if (contIndex < userSubjects.length - 1) {
                        let newIndex = contIndex + 1;
                        contRef.current?.scrollToIndex({
                          index: newIndex,
                          animated: true,
                        });
                        setContIndex(newIndex);
                      }
                    }}
                    onMouseEnter={() => setContHoveredRight(true)}
                    onMouseLeave={() => setContHoveredRight(false)}
                    onPressIn={() => setContPressedRight(true)}
                    onPressOut={() => setContPressedRight(false)}
                    activeOpacity={1}
                  >
                    <Image
                      source={"assets/chevron.png"}
                      style={styles.arrowButton}
                    />
                  </TouchableOpacity>
                )}
              </View>
            </View>
          )}
          <View>
            {subjects.map((subject, index) => {
              if (subject.data.length > 0) {
                const category = subject.category.replaceAll("-", " ");
                const groupedData = groupByTitle(subject.data);

                return (
                  <View key={index}>
                    <Text style={styles.title}>{category}</Text>
                    <View
                      style={{
                        width: ws,
                        flexDirection: "row",
                      }}
                    >
                      {ws > 950 && (
                        <TouchableOpacity
                          style={[
                            styles.navButton,
                            {
                              opacity:
                                hoveredLeft === index
                                  ? 0.5
                                  : pressedLeft === index
                                  ? 1
                                  : 0,
                            },
                          ]}
                          onPress={() => {
                            if (currentIndex[index] > 0) {
                              let newIndex = currentIndex;
                              newIndex[index] = currentIndex[index] - 1;
                              ref.current[index]?.scrollToIndex({
                                index: newIndex[index],
                                animated: true,
                              });
                              setCurrentIndex(newIndex);
                            }
                          }}
                          onMouseEnter={() => setHoveredLeft(index)}
                          onMouseLeave={() => setHoveredLeft(-1)}
                          onPressIn={() => setPressedLeft(index)}
                          onPressOut={() => setPressedLeft(-1)}
                          activeOpacity={1}
                        >
                          <Image
                            source={"assets/chevron.png"}
                            style={[
                              styles.arrowButton,
                              { transform: [{ scaleX: -1 }] },
                            ]}
                          />
                        </TouchableOpacity>
                      )}
                      <FlatList
                        ref={(element) => ref.current.push(element)}
                        // initialScrollIndex={groupedData.length - 1}
                        onScroll={(event) => {
                          handleScroll(
                            index,
                            event.nativeEvent.contentOffset.x
                          );
                        }}
                        scrollEventThrottle={16}
                        data={groupedData}
                        keyExtractor={(item, index) => index.toString()}
                        renderItem={({ item }) => {
                          const keysAndDestinations = subject.data
                            .filter((data) => data.title === item.title)
                            .map((data) => ({
                              key: data.key,
                              destination: data.destination,
                              totalQuestions: data.question_count,
                            }));

                          return (
                            <SubjectButton
                              title={item.title.replaceAll("-", " ")}
                              subject={item.key}
                              iconName={item.icon}
                              navigation={navigation}
                              keysAndDestinations={keysAndDestinations}
                            />
                          );
                        }}
                        horizontal
                        showsHorizontalScrollIndicator={false}
                        style={{ flex: 1, maxWidth: ws }}
                        contentContainerStyle={{
                          paddingVertical: 4,
                          paddingHorizontal: 32,
                          marginBottom: 8,
                        }}
                      />
                      {ws > 950 && (
                        <TouchableOpacity
                          style={[
                            styles.navButton,
                            {
                              opacity:
                                hoveredRight === index
                                  ? 0.5
                                  : pressedRight === index
                                  ? 1
                                  : 0,
                            },
                          ]}
                          onPress={() => {
                            if (currentIndex[index] < groupedData.length - 1) {
                              let newIndex = currentIndex;
                              newIndex[index] = currentIndex[index] + 1;
                              ref.current[index]?.scrollToIndex({
                                index: newIndex[index],
                                animated: true,
                              });
                              setCurrentIndex(newIndex);
                            }
                          }}
                          onMouseEnter={() => setHoveredRight(index)}
                          onMouseLeave={() => setHoveredRight(-1)}
                          onPressIn={() => setPressedRight(index)}
                          onPressOut={() => setPressedRight(-1)}
                          activeOpacity={1}
                        >
                          <Image
                            source={"assets/chevron.png"}
                            style={styles.arrowButton}
                          />
                        </TouchableOpacity>
                      )}
                    </View>
                  </View>
                );
              }
            })}
          </View>
        </View>
        <View style={styles.linksContainer}>
          <View style={styles.hintsContainer}>
            <Pressable
              onPress={() =>
                navigation.navigate("hintGalleryPage", {
                  subject: "Arithmetic",
                })
              }
            >
              <Text style={styles.link}>Arithmetic Hint Gallery</Text>
            </Pressable>
            <Pressable
              onPress={() =>
                navigation.navigate("hintGalleryPage", {
                  subject: "Trigonometry",
                })
              }
            >
              <Text style={styles.link}>Trigonometry Hint Gallery</Text>
            </Pressable>
            <Pressable
              onPress={() =>
                navigation.navigate("hintGalleryPage", {
                  subject: "Calculus",
                })
              }
            >
              <Text style={styles.link}>Calculus Hint Gallery</Text>
            </Pressable>
          </View>
          <Pressable
            onPress={() =>
              Linking.openURL(
                "mailto:richkingsford@grapeassignments.com?subject=Bulk%20discounts%20for%20Grape%20Assignments"
              )
            }
            style={styles.link}
          >
            <Text style={styles.link}>Ask about bulk discounts</Text>
          </Pressable>
        </View>
      </ScrollView>
    </View>
  );
}

export default HomePage;

const styles = StyleSheet.create({
  background: {
    position: "absolute",
    top: 0,
    zIndex: -1,
  },
  background_bottom: {
    position: "absolute",
    bottom: 0,
    zIndex: -1,
  },
  container: {
    paddingHorizontal:
      ws > 1295 ? 32 * ratio : ws > 675 ? 32 * cratio : 32 * pratio,
    alignItems: "center",
    paddingBottom: 144,
  },
  header: {
    marginBottom: 16,
    padding: 16,
    width: "100%",
  },
  row: {
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
    paddingVertical:
      ws > 1295 ? 16 * ratio : ws > 675 ? 16 * cratio : 16 * pratio,
    gap: ws > 1295 ? 30 * ratio : ws > 675 ? 30 * cratio : 30 * pratio,
  },
  rowHeader: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  logoContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: ws <= 480 ? 0 : 32,
    flexWrap: "wrap",
  },
  logo: {
    width: 40,
    height: 40,
    resizeMode: "contain",
  },
  textLogo: {
    fontSize: ws <= 480 ? 24 : 32,
    marginLeft: 8,
  },
  title: {
    paddingTop: 16,
    paddingLeft: 32,
    fontSize: ws > 1000 ? 32 : 24,
    marginVertical: 16,
    fontWeight: "bold",
  },
  greenCard: {
    width: ws > 1000 ? 800 : ws,
    height: ws > 1000 ? 255 : ws >= 768 ? (220 * ws) / 800 : (256 * ws) / 600,
    backgroundColor: "#7ab728",
    alignItems: "center",
  },
  greenSubject: {
    fontWeight: "bold",
    color: "white",
    fontSize: ws > 1000 ? "85%" : `${(95 * ws) / 1000}%`,
  },
  cardH2: {
    fontWeight: "400",
    color: "white",
    fontSize: ws > 900 ? 16 : ws > 700 ? 15 : 13,
    marginBottom: ws > 1000 ? 2 : 1,
  },
  greenContent: {
    width: ws > 1000 ? 600 : (600 * ws) / 1000,
    height: ws > 1000 ? 150 : (150 * ws) / 1000,
    flexDirection: "row",
  },
  classBtn: {
    width: "100%",
    height: ws > 1000 ? 35 : (35 * ws) / 1000,
    alignItems: "center",
    justifyContent: "center",
  },
  btnText: {
    color: "white",
    fontWeight: "bold",
    fontSize: ws > 1000 ? "80%" : `${(80 * ws) / 1000}%`,
  },
  teacherImage: {
    width: ws > 1000 ? 146 : ws >= 768 ? (146 * ws) / 1000 : (146 * ws) / 600,
    height: ws > 1000 ? 172 : ws >= 768 ? (172 * ws) / 1000 : (172 * ws) / 600,
    position: "absolute",
    bottom: ws > 1000 ? -22 : ws >= 768 ? -(14 * ws) / 800 : -(114 * ws) / 600,
  },

  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 10,
    marginBottom: 10,
  },
  navButton: {
    marginVertical: 8,
  },
  navButtonText: {
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
  },
  arrowButton: {
    height: "100%",
    width: 40,
  },
  linksContainer: {
    flexDirection: ws > 700 ? "row" : "column",
    justifyContent: "space-between",
    paddingHorizontal: ws > 1000 ? 32 : 24,
    paddingVertical: 16,
    backgroundColor: "#FFFFFFD9",
  },
  hintsContainer: {
    flexDirection: ws > 700 ? "row" : "column",
    flexWrap: "wrap",
    gap: ws > 1000 ? 20 : ws > 700 ? 16 : 12,
    marginBottom: ws < 700 ? 12 : 0,
  },
  link: {
    fontSize: ws > 1000 ? 18 : ws > 700 ? 16 : 14,
    color: "#0645AD",
    textDecorationLine: "underline",
  },
});
